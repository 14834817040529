<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="27"
    height="29"
    viewBox="0 0 27 29"
  >
    <defs>
      <path
        id="towoa"
        d="M1334.52 410.97h21.61l-10.8-7.76zm23.16.99h-24.7a.5.5 0 1 1-.29-.9l12.35-8.86a.5.5 0 0 1 .58 0l12.29 8.83a.5.5 0 0 1-.23.93z"
      />
      <path
        id="towob"
        d="M1351.26 411.05l-6.12-4.52-5.99 4.43c-.8.6-1.48.76-1.64.54-.16-.21-.46-.21 0-.54l7.34-5.44a.5.5 0 0 1 .59 0l7.42 5.5c.42.3-.84.6-1.6.03z"
      />
      <path
        id="towoc"
        d="M1335.46 412.45h1.96v-.5h-1.96zm2.47.98h-2.97a.5.5 0 0 1-.49-.5v-1.47c0-.27.22-.49.5-.49h2.96c.27 0 .5.22.5.5v1.47a.5.5 0 0 1-.5.5z"
      />
      <path
        id="towod"
        d="M1341.39 412.45h1.96v-.5h-1.96zm2.47.98h-2.97a.5.5 0 0 1-.49-.5v-1.47c0-.27.22-.49.5-.49h2.96c.27 0 .5.22.5.5v1.47a.5.5 0 0 1-.5.5z"
      />
      <path
        id="towoe"
        d="M1347.3 412.45h1.97v-.5h-1.96zm2.48.98h-2.97a.5.5 0 0 1-.49-.5v-1.47c0-.27.22-.49.5-.49h2.96c.27 0 .5.22.5.5v1.47a.5.5 0 0 1-.5.5z"
      />
      <path
        id="towof"
        d="M1353.24 412.45h1.96v-.5h-1.96zm2.47.98h-2.97a.5.5 0 0 1-.49-.5v-1.47c0-.27.22-.49.5-.49h2.96c.27 0 .5.22.5.5v1.47a.5.5 0 0 1-.5.5z"
      />
      <path
        id="towog"
        d="M1335.46 425.76h1.96v-.5h-1.96zm2.47.97h-2.97a.5.5 0 0 1-.49-.5v-1.47c0-.27.22-.49.5-.49h2.96c.27 0 .5.22.5.5v1.47a.5.5 0 0 1-.5.5z"
      />
      <path
        id="towoh"
        d="M1341.39 425.76h1.96v-.5h-1.96zm2.47.97h-2.97a.5.5 0 0 1-.49-.5v-1.47c0-.27.22-.49.5-.49h2.96c.27 0 .5.22.5.5v1.47a.5.5 0 0 1-.5.5z"
      />
      <path
        id="towoi"
        d="M1347.3 425.76h1.97v-.5h-1.96zm2.48.97h-2.97a.5.5 0 0 1-.49-.5v-1.47c0-.27.22-.49.5-.49h2.96c.27 0 .5.22.5.5v1.47a.5.5 0 0 1-.5.5z"
      />
      <path
        id="towoj"
        d="M1353.24 425.76h1.96v-.5h-1.96zm2.47.97h-2.97a.5.5 0 0 1-.49-.5v-1.47c0-.27.22-.49.5-.49h2.96c.27 0 .5.22.5.5v1.47a.5.5 0 0 1-.5.5z"
      />
      <path
        id="towok"
        d="M1332.99 429.69h24.69v-1h-24.7zm25.18.97h-25.68a.5.5 0 0 1-.49-.49v-1.97c0-.27.22-.49.5-.49h25.67c.28 0 .5.22.5.5v1.96a.5.5 0 0 1-.5.5z"
      />
      <path id="towol" d="M1335.45 425.25a.5.5 0 0 1-.49-.5v-11.8a.5.5 0 0 1 .99 0v11.8a.5.5 0 0 1-.5.5z" />
      <path id="towom" d="M1337.43 425.25a.5.5 0 0 1-.49-.5v-11.8a.5.5 0 0 1 .99 0v11.8a.5.5 0 0 1-.5.5z" />
      <path id="towon" d="M1341.38 425.25a.5.5 0 0 1-.49-.5v-11.8a.5.5 0 0 1 .99 0v11.8a.5.5 0 0 1-.5.5z" />
      <path id="towoo" d="M1343.35 425.25a.5.5 0 0 1-.49-.5v-11.8a.5.5 0 0 1 .99 0v11.8a.5.5 0 0 1-.5.5z" />
      <path id="towop" d="M1347.3 425.25a.5.5 0 0 1-.49-.5v-11.8a.5.5 0 0 1 .99 0v11.8a.5.5 0 0 1-.5.5z" />
      <path id="towoq" d="M1349.28 425.25a.5.5 0 0 1-.49-.5v-11.8a.5.5 0 0 1 .99 0v11.8a.5.5 0 0 1-.5.5z" />
      <path id="towor" d="M1353.23 425.25a.5.5 0 0 1-.49-.5v-11.8a.5.5 0 0 1 .99 0v11.8a.5.5 0 0 1-.5.5z" />
      <path id="towos" d="M1355.21 425.25a.5.5 0 0 1-.49-.5v-11.8a.5.5 0 0 1 .99 0v11.8a.5.5 0 0 1-.5.5z" />
      <path
        id="towot"
        d="M1333.98 427.72h22.7v-1h-22.7zm23.2.97h-23.7a.5.5 0 0 1-.49-.49v-1.97c0-.27.22-.49.5-.49h23.7c.27 0 .5.22.5.5v1.96a.5.5 0 0 1-.5.5z"
      />
      <path
        id="towou"
        d="M1345.33 408.02a.51.51 0 0 1-.35-.14.5.5 0 0 1-.14-.35.5.5 0 0 1 .14-.35.52.52 0 0 1 .7 0c.1.09.15.22.15.35 0 .12-.06.25-.15.35a.52.52 0 0 1-.35.14z"
      />
      <path
        id="towov"
        d="M1345.33 409.99a.52.52 0 0 1-.35-.14.52.52 0 0 1-.14-.35.5.5 0 0 1 .14-.35.52.52 0 0 1 .7 0c.1.09.15.21.15.35a.5.5 0 0 1-.15.35.52.52 0 0 1-.35.14z"
      />
      <path
        id="towow"
        d="M1347.3 409.99a.51.51 0 0 1-.35-.14.5.5 0 0 1 0-.7.52.52 0 0 1 .7 0c.1.09.15.21.15.35 0 .12-.06.25-.15.35a.52.52 0 0 1-.35.14z"
      />
      <path
        id="towox"
        d="M1343.35 409.99a.51.51 0 0 1-.35-.14.5.5 0 0 1 0-.7.52.52 0 0 1 .7 0c.1.09.15.21.15.35 0 .12-.06.25-.15.35a.51.51 0 0 1-.35.14z"
      />
    </defs>
    <g>
      <g transform="translate(-1332 -402)">
        <g>
          <g>
            <g><use fill="#363636" xlink:href="#towoa" /></g>
          </g>
          <g>
            <g><use fill="#363636" xlink:href="#towob" /></g>
          </g>
          <g>
            <g><use fill="#363636" xlink:href="#towoc" /></g>
          </g>
          <g>
            <g><use fill="#363636" xlink:href="#towod" /></g>
          </g>
          <g>
            <g><use fill="#363636" xlink:href="#towoe" /></g>
          </g>
          <g>
            <g><use fill="#363636" xlink:href="#towof" /></g>
          </g>
          <g>
            <g><use fill="#363636" xlink:href="#towog" /></g>
          </g>
          <g>
            <g><use fill="#363636" xlink:href="#towoh" /></g>
          </g>
          <g>
            <g><use fill="#363636" xlink:href="#towoi" /></g>
          </g>
          <g>
            <g><use fill="#363636" xlink:href="#towoj" /></g>
          </g>
          <g>
            <g><use fill="#363636" xlink:href="#towok" /></g>
          </g>
          <g>
            <g><use fill="#363636" xlink:href="#towol" /></g>
          </g>
          <g>
            <g><use fill="#363636" xlink:href="#towom" /></g>
          </g>
          <g>
            <g><use fill="#363636" xlink:href="#towon" /></g>
          </g>
          <g>
            <g><use fill="#363636" xlink:href="#towoo" /></g>
          </g>
          <g>
            <g><use fill="#363636" xlink:href="#towop" /></g>
          </g>
          <g>
            <g><use fill="#363636" xlink:href="#towoq" /></g>
          </g>
          <g>
            <g><use fill="#363636" xlink:href="#towor" /></g>
          </g>
          <g>
            <g><use fill="#363636" xlink:href="#towos" /></g>
          </g>
          <g>
            <g><use fill="#363636" xlink:href="#towot" /></g>
          </g>
        </g>
        <g>
          <g><use fill="#363636" xlink:href="#towou" /></g>
        </g>
        <g>
          <g><use fill="#363636" xlink:href="#towov" /></g>
        </g>
        <g>
          <g><use fill="#363636" xlink:href="#towow" /></g>
        </g>
        <g>
          <g><use fill="#363636" xlink:href="#towox" /></g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TheArchMonumentIcon',
};
</script>
